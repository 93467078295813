import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { get, set } from '@/utils/vuex';
import { IRootState } from '@/store';

export interface IToast {
  message: string;
  type?: 'info' | 'error';
}

export interface IToastState {
  toast: IToast | null;
}

const state: IToastState = {
  toast: null,
};

const getters: GetterTree<IToastState, IRootState> = {
  toast: get('toast'),
};

const mutations: MutationTree<IToastState> = {
  setToast: set('toast'),
};

const actions: ActionTree<IToastState, IRootState> = {};

const module: Module<IToastState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default module;
