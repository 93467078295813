import VueI18n, { DateTimeFormat } from 'vue-i18n';
import { Language } from '@/api';
import Vue from 'vue';

import de from '../locales/de.json';
import en from '../locales/en.json';
import es from '../locales/es.json';
import fr from '../locales/fr.json';

Vue.use(VueI18n);

// XXX need to make this smarter once we add more languages
const defaultLocale: Language = navigator.language === 'de' ? 'de' : 'en';

const dateFormat: DateTimeFormat = {
  long: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  },
};

export default new VueI18n({
  messages: {
    en,
    de,
    fr,
    es,
  },
  dateTimeFormats: {
    de: dateFormat,
    en: dateFormat,
    es: dateFormat,
    fr: dateFormat,
  },
  locale: defaultLocale,
  fallbackLocale: 'en',
});
