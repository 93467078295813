import router from '@/router';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

export function setupPageTracking(trackingId: string): void {
  Vue.use(VueAnalytics, {
    id: trackingId,
    router,
    // XXX Enable to test GA during development
    // debug: {
    //   enabled: true,
    //   sendHitTask: false,
    // },
  });
}
