import * as api from '@/api';
import i18n from '@/plugins/i18n';
import { NavigationGuard } from 'vue-router';
import store from '@/store';

export const confirmSubscriber: NavigationGuard = async (to, _from, next) => {
  const { token } = to.query;
  if (typeof token !== 'string') {
    store.commit('setToast', { message: i18n.t('confirmSubscriber.noTokenErrorMessage') });
    next('/');
    return;
  }
  try {
    await api.confirmSubscription({ token });
    store.commit('setToast', { message: i18n.t('confirmSubscriber.successMessage') });
  } catch (err: any) {
    store.commit('setToast', {
      message: i18n.t('confirmSubscriber.errorMessage', {
        message: err.data.message ?? i18n.t('global.unknownError'),
      }),
      type: 'error',
    });
  }
  next('/');
};
