import * as api from '@/api';
import { ActionContext, Module } from 'vuex';
import { get, set } from '@/utils/vuex';
import { IComponent } from '@/api';
import { IRootState } from '..';

export interface IComponentState {
  component: IComponent | null;
  components: IComponent[];
}

const state: IComponentState = {
  component: null,
  components: [],
};

const getters = {
  component: get('component'),
  components: get('components'),
};

const mutations = {
  setComponent: set('component'),
  setComponents: set('components'),
};

const actions = {
  async listComponents({ commit }: ActionContext<IComponentState, IRootState>, pageId: string): Promise<IComponent[]> {
    const components = await api.listComponents(pageId);
    commit('setComponents', components);
    return components;
  },

  async getComponent({ commit }: ActionContext<IComponentState, IRootState>, componentId: string): Promise<IComponent> {
    const component = await api.getComponent(componentId);
    commit('setComponent', component);
    return component;
  },
};

const component: Module<IComponentState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default component;
