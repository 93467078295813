import { NavigationGuard } from 'vue-router';
import store from '@/store';

const isValidPageGuard: NavigationGuard = async (to, _from, next) => {
  try {
    await store.dispatch('resolvePage');
    next();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    const name = err.status === 404 ? 'not-found' : 'error';
    const params = { '0': to.path };
    next({ name, params });
  }
};

export default isValidPageGuard;
