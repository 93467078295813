import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '..';

export interface ILoadingState {
  /** The number of currently ongoing requests. */
  loading: number;
}

const state: ILoadingState = {
  loading: 0,
};

const getters: GetterTree<ILoadingState, IRootState> = {
  isLoading: (state: ILoadingState): boolean => state.loading > 0,
};

const actions: ActionTree<ILoadingState, IRootState> = {};

const mutations: MutationTree<ILoadingState> = {
  startLoading(state: ILoadingState) {
    state.loading++;
  },
  finishedLoading(state: ILoadingState) {
    // timeout to prevent on/off “flickering”,
    // when a new request follows immediately
    setTimeout(() => {
      state.loading--;
    }, 50);
  },
};

const loading: Module<ILoadingState, IRootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};

export default loading;
