
















import { Component, Vue } from 'vue-property-decorator';
import { emailRules } from '@/utils/validation';
import { Getter } from 'vuex-class';
import { headerTextColor } from '@/utils/display';
import { IPage } from '@/api';
import SubscriberButton from './SubscriberButton.vue';

@Component<PageBar>({
  components: { SubscriberButton },
  data: () => ({ emailRules: emailRules }),
  methods: { headerTextColor },
})
export default class PageBar extends Vue {
  @Getter
  protected page!: IPage;
}
