import { IPage } from '@/api';

const cutHex = (h: string) => (h.charAt(0) === '#' ? h.substring(1, 7) : h);
const hexToR = (h: string): number => parseInt(cutHex(h).substring(0, 2), 16);
const hexToG = (h: string): number => parseInt(cutHex(h).substring(2, 4), 16);
const hexToB = (h: string): number => parseInt(cutHex(h).substring(4, 6), 16);
const colorBrightness = (hex: string): number => (hexToR(hex) * 299 + hexToG(hex) * 587 + hexToB(hex) * 114) / 1000;
const textColorShouldBeDark = (hex: string): boolean => colorBrightness(hex) > 130;

export function headerTextColor(page: IPage): string {
  return !page?.heroCover && textColorShouldBeDark(page.colorHeader) ? '#000' : '#fff';
}
