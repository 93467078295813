


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IEvent } from '@/api';

@Component
export default class Event extends Vue {
  @Prop(Object)
  protected event!: IEvent;

  get label(): string {
    switch (this.event.status) {
      case 'investigating':
      case 'identified':
      case 'monitoring':
      case 'resolved':
        return `pageEvent.status.${this.event.status}`;
      default:
        return 'pageEvent.status.unknown';
    }
  }

  get color(): string | undefined {
    switch (this.event.status) {
      case 'investigating':
      case 'identified':
        return '#f38020';
      case 'monitoring':
        return '#2c7cb0';
      case 'resolved':
      default:
        return undefined;
    }
  }
}
