


















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { headerTextColor } from '@/utils/display';
import { IPage } from '@/api';
import PageBar from '@/components/PageBar.vue';

@Component<PageHeader>({
  components: { PageBar },
  methods: { headerTextColor },
})
export default class PageHeader extends Vue {
  @Getter
  protected page!: IPage;
}
