









import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IPage } from '@/api';
import { MetaInfo } from 'vue-meta';
import Toast from '@/components/Toast.vue';

@Component<Wrapper>({
  components: { Toast },
  metaInfo(): MetaInfo {
    const shouldIndexPage = !this.page?.hiddenFromSearch;
    const robotsContent = shouldIndexPage ? 'index,follow' : 'noindex,follow';
    const faviconHref = this.page?.favicon || '/icons/favicon.ico';
    const logoHref = this.page?.logo || '/icons/logo-300x300.png';
    const themeColor = this.page?.colorHeader || '#fcdf02';
    return {
      meta: [
        { name: 'robots', content: robotsContent },
        { name: 'theme-color', content: themeColor },
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: faviconHref },
        { rel: 'apple-touch-icon', href: logoHref },
        { rel: 'shortcut icon', href: faviconHref },
      ],
    };
  },
})
export default class Wrapper extends Vue {
  @Getter
  protected page!: IPage;
}
