













import { Component, Vue } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';
import { MetaInfo } from 'vue-meta';

@Component<Error>({
  metaInfo(): MetaInfo {
    return {
      title: i18n.t('error.metaTitle') as string,
    };
  },
})
export default class Error extends Vue {
  // nothing here (yet)
}
