

















import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CookieDialog extends Vue {
  @Getter
  protected showCookieDialog!: boolean;

  @Action
  protected closeCookieDialog!: (outcome: 'agree' | 'reject') => void;
}
