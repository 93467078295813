import * as config from '@/config';
import { AckeeInstance, ActionAttributes, create } from 'ackee-tracker';

let _ackeeInstance: AckeeInstance | undefined;
let _ackeeEvents: Record<string, string> | undefined;

const ackeePromise = async () => {
  const { ackeeUrl, ackeeDomainId, ackeeEventsJson } = await config.load();
  if (!ackeeUrl || !ackeeDomainId || !ackeeEventsJson) {
    // eslint-disable-next-line no-console
    console.log('Ackee configuration is missing');
    return;
  }
  if (!_ackeeInstance) {
    // per default, `localhost` is ignored; but when running with a `localhost`
    // Ackee instance (development), we DON'T want to ignore :-)
    const ignoreLocalhost = !ackeeUrl.includes('localhost');
    _ackeeInstance = create(ackeeUrl, { detailed: true, ignoreLocalhost, ignoreOwnVisits: false });
    _ackeeEvents = JSON.parse(ackeeEventsJson);
  }
  return _ackeeInstance;
};

/** Hook for the Vue router’s `afterEach` event. */
export async function ackeeHook() {
  const ackee = await ackeePromise();
  const { ackeeDomainId } = await config.load();
  if (!ackeeDomainId) {
    return;
  }
  ackee?.record(ackeeDomainId);
}

export async function ackeeAction(eventLabel: string, attributes: ActionAttributes): Promise<void> {
  const ackee = await ackeePromise();
  const eventId = _ackeeEvents?.[eventLabel];
  if (!eventId) {
    // eslint-disable-next-line no-console
    console.warn(`No such Ackee action: ${eventLabel}`);
    return;
  }
  return ackee?.action(eventId, attributes);
}
