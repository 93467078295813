

















































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { IComponent, IEvent, IPage, ObjectId } from '@/api';
import { IListEventsParams } from '@/store/modules/event';
import { IToast } from '@/store/modules/toast';
import { MetaInfo } from 'vue-meta';
import PageComponent from '@/components/PageComponent.vue';
import PageEvent from '@/components/PageEvent.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageHeader from '@/components/PageHeader.vue';
import PageImpact from '@/components/PageImpact.vue';

@Component<PageStatus>({
  components: { PageHeader, PageFooter, PageComponent, PageEvent, PageImpact },
  metaInfo(): MetaInfo {
    return {
      title: this.page ? `${this.page.name}: Status` : 'Status',
    };
  },
})
export default class PageStatus extends Vue {
  @Getter
  protected page!: IPage;

  @Getter
  protected components!: IComponent[];

  @Getter('unresolvedEvents')
  protected activeEvents!: IEvent[];

  @Getter('events')
  protected previousEvents!: IEvent[];

  @Action
  protected listComponents!: (pageId: ObjectId) => Promise<void>;

  @Action('listUnresolvedEvents')
  protected listActiveEvents!: (pageId: ObjectId) => Promise<void>;

  @Action('listEvents')
  protected listPreviousEvents!: (params: IListEventsParams) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  async created(): Promise<void> {
    await Promise.all([
      this.listComponents(this.page._id),
      this.listPreviousEvents({ pageId: this.page._id, resolved: true, createdAfter: this.lastWeek() }),
      this.listActiveEvents(this.page._id),
    ]);
  }

  get parentComponents(): IComponent[] {
    return this.components.filter((c) => !c.parent);
  }

  protected lastWeek(): string {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
  }

  protected hasChildren(parent: IComponent): boolean {
    return !!this.components.find((c) => c.parent === parent._id);
  }

  protected childComponents(parent: IComponent): IComponent[] {
    return this.components.filter((c) => c.parent === parent._id);
  }
}
