/**
 * pagefate-api
 * 0.1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from 'oazapfts/lib/runtime';
import * as QS from 'oazapfts/lib/runtime/query';
export const defaults: Oazapfts.RequestOpts = {
  baseUrl: '/',
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
  server1: '/',
};
export type ObjectId = string;
export type ImpactType = 'operational' | 'degraded' | 'partial' | 'outage' | 'maintenance';
export type UserRole = 'user' | 'superuser';
export type IUser = {
  _id: ObjectId;
  kind: 'user';
  email: string;
  role: UserRole;
  confirmedAt?: string;
  lastLoginAt?: string;
  createdAt: string;
  updatedAt: string;
};
export type IComponent = {
  _id: ObjectId;
  kind: 'component';
  name: string;
  description?: string;
  page: ObjectId;
  parent?: ObjectId;
  impact: ImpactType;
  createdAt: string;
  updatedAt: string;
  createdBy?: IUser;
  updatedBy?: IUser;
};
export type ICreateOrUpdateComponentParams = {
  name: string;
  description?: string;
  page: ObjectId;
  parent?: ObjectId;
};
export type EventType = 'incident' | 'maintenance';
export type StatusType = 'investigating' | 'identified' | 'monitoring' | 'resolved';
export type IEvent = {
  _id: ObjectId;
  kind: 'event';
  type: EventType;
  status?: StatusType;
  title: string;
  body?: string;
  resolvedAt?: string;
  page: ObjectId;
  createdAt: string;
  updatedAt: string;
  createdBy?: IUser;
  updatedBy?: IUser;
};
export type ICreateOrUpdateEventParams = {
  type: EventType;
  title: string;
  resolvedAt?: string;
  page: ObjectId;
};
export type IFileUpload = {
  name: string;
};
export type IMember = {
  _id: ObjectId;
  kind: 'member';
  email: string;
  page: ObjectId;
  acceptedAt?: string;
  createdAt: string;
  updatedAt: string;
  createdBy?: IUser;
  updatedBy?: IUser;
};
export type IInviteMemberParams = {
  email: string;
  page: ObjectId;
};
export type IReinviteMemberParams = {
  member: ObjectId;
};
export type IConfirmMemberParams = {
  token: string;
};
export type IMemberTokenCheck = {
  userExists: boolean;
  email: string;
};
export type Language = 'de' | 'en' | 'es' | 'fr';
export type IPage = {
  _id: ObjectId;
  kind: 'page';
  name: string;
  slug: string;
  allowPageSubscribers: boolean;
  colorHeader: string;
  favicon?: string;
  heroCover?: string;
  hiddenFromSearch: boolean;
  logo?: string;
  description?: string;
  googleAnalytics?: string;
  customDomain?: string;
  createdAt: string;
  updatedAt: string;
  impact: ImpactType;
  url: string;
  createdBy?: IUser;
  updatedBy?: IUser;
  owner?: IUser;
  activeSubscription?: boolean;
  paddleUpdateUrl?: string;
  paddleCancelUrl?: string;
  paddleSubscriptionEnd?: string;
  paddleNextBillingDate?: string;
  language: Language;
};
export type ISlugCheck = {
  result: 'free' | 'taken' | 'invalid';
};
export type ICreateOrUpdatePageParams = {
  name: string;
  slug: string;
  allowPageSubscribers?: boolean;
  colorHeader?: string;
  favicon?: string;
  heroCover?: string;
  hiddenFromSearch?: boolean;
  logo?: string;
  description?: string;
  googleAnalytics?: string;
  customDomain?: string;
  language?: Language;
};
export type ICreateSubscriberParams = {
  email: string;
  page: ObjectId;
};
export type IReinviteSubscriberParams = {
  subscriber: ObjectId;
};
export type ISubscriber = {
  _id: ObjectId;
  kind: 'subscriber';
  email: string;
  confirmedAt?: string;
  page: ObjectId;
  createdAt: string;
  updatedAt: string;
  createdBy?: IUser;
  updatedBy?: IUser;
};
export type IConfirmSubscriberParams = {
  token: string;
};
export type IUnsubscribeSubscriberParams = {
  token: string;
};
export type IEventAffectedComponent = {
  _id: ObjectId;
  impact: ImpactType;
  component: ObjectId;
};
export type IUpdate = {
  _id: ObjectId;
  kind: 'update';
  page: ObjectId;
  event: ObjectId;
  status: StatusType;
  body: string;
  occurredAt: string;
  createdAt: string;
  updatedAt: string;
  affectedComponents: IEventAffectedComponent[];
  createdBy?: IUser;
  updatedBy?: IUser;
};
export type ICreateOrUpdateEventAffectedComponent = {
  _id?: ObjectId;
  impact: ImpactType;
  component: ObjectId;
};
export type ICreateOrUpdateUpdateParams = {
  page: ObjectId;
  event: ObjectId;
  status: StatusType;
  body: string;
  sendNotification?: boolean;
  occurredAt?: string;
  affectedComponents: ICreateOrUpdateEventAffectedComponent[];
};
export type IRegisterUserParams = {
  email: string;
  password: string;
};
export type ITokenResult = {
  token: string;
};
export type Action = 'create' | 'read' | 'update' | 'delete';
export type StringSubject = 'user' | 'page' | 'event' | 'component' | 'subscriber' | 'file' | 'member' | 'update';
export type IAppAbilityRule = {
  action: Action | Action[];
  fields?: string | string[];
  subject: StringSubject;
};
export type IUserWithAbilities = {
  _id: ObjectId;
  kind: 'user';
  email: string;
  role: UserRole;
  confirmedAt?: string;
  lastLoginAt?: string;
  createdAt: string;
  updatedAt: string;
  abilityRules: IAppAbilityRule[];
};
export type ICreateOrUpdateUserParams = {
  email: string;
  role: UserRole;
};
export type IConfirmParams = {
  token: string;
};
export type ILoginParams = {
  email: string;
  password: string;
};
export type IRecoverParams = {
  email: string;
};
export type IResetParams = {
  resetId: string;
  token: string;
};
export type IResetCheckResult = {
  email: string;
};
export type IResetWithPasswordParams = IResetParams & {
  password: string;
};
/**
 * Get a component by its ID.
 */
export function getComponent(componentId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IComponent;
    }>(`/component/${componentId}`, {
      ...opts,
    })
  );
}
/**
 * Update an existing component by its ID.
 */
export function updateComponent(
  componentId: ObjectId,
  iCreateOrUpdateComponentParams: ICreateOrUpdateComponentParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IComponent;
    }>(
      `/component/${componentId}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: iCreateOrUpdateComponentParams,
      })
    )
  );
}
/**
 * Delete an existing component by its ID.
 */
export function deleteComponent(componentId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/component/${componentId}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Get a list of all components to which the current user has access to.
 */
export function listComponents(pageId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IComponent[];
    }>(
      `/component${QS.query(
        QS.form({
          pageId,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Create a new component.
 */
export function createComponent(
  iCreateOrUpdateComponentParams: ICreateOrUpdateComponentParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: IComponent;
    }>(
      '/component',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iCreateOrUpdateComponentParams,
      })
    )
  );
}
/**
 * Get a event by its ID.
 */
export function getEvent(eventId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IEvent;
    }>(`/event/${eventId}`, {
      ...opts,
    })
  );
}
/**
 * Update an existing event by its ID.
 */
export function updateEvent(
  eventId: ObjectId,
  iCreateOrUpdateEventParams: ICreateOrUpdateEventParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IEvent;
    }>(
      `/event/${eventId}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: iCreateOrUpdateEventParams,
      })
    )
  );
}
/**
 * Delete an existing event by its ID.
 */
export function deleteEvent(eventId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/event/${eventId}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Get a list of all events to which the current user has access to.
 */
export function listEvents(
  pageId: ObjectId,
  {
    resolved,
    createdBefore,
    createdAfter,
  }: {
    resolved?: boolean;
    createdBefore?: string;
    createdAfter?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IEvent[];
    }>(
      `/event${QS.query(
        QS.form({
          pageId,
          resolved,
          createdBefore,
          createdAfter,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Create a new event.
 */
export function createEvent(iCreateOrUpdateEventParams: ICreateOrUpdateEventParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: IEvent;
    }>(
      '/event',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iCreateOrUpdateEventParams,
      })
    )
  );
}
/**
 * Upload a new file (supported file types: jpg, png, gif, ico).
 */
export function uploadFile(
  body: {
    file: Blob;
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: IFileUpload;
    }>(
      '/file/upload',
      oazapfts.multipart({
        ...opts,
        method: 'POST',
        body,
      })
    )
  );
}
/**
 * Get a list of all members to which the current user has access to.
 */
export function listMembers(pageId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IMember[];
    }>(
      `/member${QS.query(
        QS.form({
          pageId,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Invite a user to become a member of a page.
 */
export function inviteMember(iInviteMemberParams: IInviteMemberParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: IMember;
    }>(
      '/member/invite',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iInviteMemberParams,
      })
    )
  );
}
/**
 * Resend an existing member invitation.
 */
export function reinviteMember(iReinviteMemberParams: IReinviteMemberParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IMember;
    }>(
      '/member/reinvite',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iReinviteMemberParams,
      })
    )
  );
}
/**
 * Confirm page membership.
 */
export function confirmMember(iConfirmMemberParams: IConfirmMemberParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IMember;
    }>(
      '/member/confirm',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iConfirmMemberParams,
      })
    )
  );
}
/**
 * Delete an existing member by its ID.
 */
export function deleteMember(memberId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/member/${memberId}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Check a token for member invitation.
 */
export function checkMemberToken(value: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IMemberTokenCheck;
    }>(
      `/member/token${QS.query(
        QS.form({
          value,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Get a page by its slug or domain (status.nodepit.com or nodepit.pagefate.com).
 */
export function resolvePage(
  {
    hostname,
  }: {
    hostname?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IPage;
    }>(
      `/page/resolve${QS.query(
        QS.form({
          hostname,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Check if the given slug is taken or available.
 */
export function checkSlug(value: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ISlugCheck;
    }>(
      `/page/slug${QS.query(
        QS.form({
          value,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Get a page by its ID.
 */
export function getPage(pageId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IPage;
    }>(`/page/${pageId}`, {
      ...opts,
    })
  );
}
/**
 * Update an existing page by its ID.
 */
export function updatePage(
  pageId: ObjectId,
  iCreateOrUpdatePageParams: ICreateOrUpdatePageParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IPage;
    }>(
      `/page/${pageId}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: iCreateOrUpdatePageParams,
      })
    )
  );
}
/**
 * Delete an existing page by its ID.
 */
export function deletePage(pageId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/page/${pageId}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Get a list of all pages to which the current user can edit.
 */
export function listPages(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IPage[];
    }>('/page', {
      ...opts,
    })
  );
}
/**
 * Create a new page.
 */
export function createPage(iCreateOrUpdatePageParams: ICreateOrUpdatePageParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: IPage;
    }>(
      '/page',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iCreateOrUpdatePageParams,
      })
    )
  );
}
/**
 * Subscribe to a page.
 */
export function subscribe(iCreateSubscriberParams: ICreateSubscriberParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/subscriber/subscribe',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iCreateSubscriberParams,
      })
    )
  );
}
export function reinviteSubscription(
  iReinviteSubscriberParams: IReinviteSubscriberParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ISubscriber;
    }>(
      '/subscriber/reinvite',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iReinviteSubscriberParams,
      })
    )
  );
}
export function confirmSubscription(iConfirmSubscriberParams: IConfirmSubscriberParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/subscriber/confirm',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iConfirmSubscriberParams,
      })
    )
  );
}
export function unsubscribe(iUnsubscribeSubscriberParams: IUnsubscribeSubscriberParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/subscriber/unsubscribe',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iUnsubscribeSubscriberParams,
      })
    )
  );
}
/**
 * Get a subscriber by its ID.
 */
export function getSubscriber(subscriberId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ISubscriber;
    }>(`/subscriber/${subscriberId}`, {
      ...opts,
    })
  );
}
/**
 * Delete an existing subscriber by its ID.
 */
export function deleteSubscriber(subscriberId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/subscriber/${subscriberId}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Get a list of all subscribers to which the current user has access to.
 */
export function listSubscribers(pageId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ISubscriber[];
    }>(
      `/subscriber${QS.query(
        QS.form({
          pageId,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Create a new subscriber.
 */
export function createSubscriber(iCreateSubscriberParams: ICreateSubscriberParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: ISubscriber;
    }>(
      '/subscriber',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iCreateSubscriberParams,
      })
    )
  );
}
/**
 * Get an update by its ID.
 */
export function getUpdate(updateId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IUpdate;
    }>(`/update/${updateId}`, {
      ...opts,
    })
  );
}
/**
 * Update an existing update by its ID.
 */
export function updateUpdate(
  updateId: ObjectId,
  iCreateOrUpdateUpdateParams: ICreateOrUpdateUpdateParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IUpdate;
    }>(
      `/update/${updateId}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: iCreateOrUpdateUpdateParams,
      })
    )
  );
}
/**
 * Delete an existing update by its ID.
 */
export function deleteUpdate(updateId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/update/${updateId}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Get a list of all updates to which the current user has access to.
 */
export function listUpdates(eventId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IUpdate[];
    }>(
      `/update${QS.query(
        QS.form({
          eventId,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Create a new update.
 */
export function createUpdate(iCreateOrUpdateUpdateParams: ICreateOrUpdateUpdateParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: IUpdate;
    }>(
      '/update',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iCreateOrUpdateUpdateParams,
      })
    )
  );
}
/**
 * Register a new user.
 */
export function register(iRegisterUserParams: IRegisterUserParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: ITokenResult;
    }>(
      '/user/register',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iRegisterUserParams,
      })
    )
  );
}
/**
 * Get information about the current user.
 */
export function getMe(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IUserWithAbilities;
    }>('/user/me', {
      ...opts,
    })
  );
}
/**
 * Get a user by its ID.
 */
export function getUser(userId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IUser;
    }>(`/user/${userId}`, {
      ...opts,
    })
  );
}
/**
 * Delete an existing user by its ID.
 */
export function deleteUser(userId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/user/${userId}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Update an existing user by its ID.
 */
export function updateUser(
  userId: ObjectId,
  iCreateOrUpdateUserParams: ICreateOrUpdateUserParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IUser;
    }>(
      `/user/${userId}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: iCreateOrUpdateUserParams,
      })
    )
  );
}
/**
 * Confirm a new user.
 */
export function confirmUser(iConfirmParams: IConfirmParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/user/confirm',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iConfirmParams,
      })
    )
  );
}
/**
 * Get a list of all users.
 */
export function listUsers(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IUser[];
    }>('/user', {
      ...opts,
    })
  );
}
/**
 * Create a new user.
 */
export function createUser(iCreateOrUpdateUserParams: ICreateOrUpdateUserParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: IUser;
    }>(
      '/user',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iCreateOrUpdateUserParams,
      })
    )
  );
}
/**
 * Login.
 */
export function login(iLoginParams: ILoginParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ITokenResult;
    }>(
      '/user/login',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iLoginParams,
      })
    )
  );
}
/**
 * Request a password reset email.
 */
export function requestPasswordReset(iRecoverParams: IRecoverParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/user/request-password-reset',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iRecoverParams,
      })
    )
  );
}
/**
 * Validate if the reset ID and token are valid.
 */
export function checkPasswordReset(iResetParams: IResetParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: IResetCheckResult;
    }>(
      '/user/check-password-reset',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iResetParams,
      })
    )
  );
}
/**
 * Set a new password.
 */
export function resetPassword(iResetWithPasswordParams: IResetWithPasswordParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ITokenResult;
    }>(
      '/user/reset-password',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: iResetWithPasswordParams,
      })
    )
  );
}
