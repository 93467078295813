import * as api from './generated';
import store from '@/store';

api.defaults.baseUrl = '/api';
api.defaults.fetch = makeCustomFetch();

export interface IFetchOptions {
  showToast?: boolean;
}

export function makeCustomFetch(opts: IFetchOptions = { showToast: true }): typeof fetch {
  // error hooks
  async function errorHandler(response: any) {
    if (!response) {
      return;
    }
    const { message, name, status } = response;
    // if enabled, show global error toast
    if (opts.showToast) {
      let fullMessage;
      if (typeof message === 'string' && message.length) {
        fullMessage = message;
      } else if (typeof name === 'string' && name.length) {
        fullMessage = name;
      } else {
        fullMessage = 'Something went wrong';
      }
      if (typeof status === 'number') {
        fullMessage += ` (status ${status})`;
      }
      store.state.toast.toast = { message: fullMessage, type: 'error' };
    }
  }

  return async (input: RequestInfo, init: RequestInit = {}): Promise<Response> => {
    // show/hide loading indicator
    store.commit('startLoading');
    try {
      const result = await fetch(input, init);
      if (!result.ok) {
        const json = await result.clone().json();
        errorHandler(json);
      }
      // even when error handler triggered,
      // return the actual result
      return result;
    } catch (err) {
      // network error (e.g. no API response)
      errorHandler(err);
      throw err;
    } finally {
      store.commit('finishedLoading');
    }
  };
}

export * from './generated'; // re-export everything
