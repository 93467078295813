





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IComponent } from '@/api';

@Component
export default class PageComponent extends Vue {
  @Prop(Object)
  protected component!: IComponent;

  @Prop(Array)
  protected components!: IComponent[];

  getText(component: IComponent): string {
    switch (component.impact) {
      case 'operational':
      case 'degraded':
      case 'partial':
      case 'outage':
      case 'maintenance':
        return `pageComponent.impact.${component.impact}`;
      default:
        return 'pageComponent.impact.unknown';
    }
  }

  getColor(component: IComponent): string {
    switch (component.impact) {
      case 'operational':
        return '#48b975';
      case 'degraded':
      case 'partial':
      case 'outage':
        return '#f38020';
      case 'maintenance':
      default:
        return '#2c7cb0';
    }
  }
}
