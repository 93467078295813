













import * as config from '@/config';
import { Component, Vue } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';
import { MetaInfo } from 'vue-meta';

@Component<NotFound>({
  metaInfo(): MetaInfo {
    return {
      title: i18n.t('notFound.metaTitle') as string,
    };
  },
})
export default class NotFound extends Vue {
  protected url = 'https://pagefate.com';

  async created(): Promise<void> {
    const data = await config.load();
    this.url = data.websiteUrl;
  }
}
