import * as api from '@/api';
import * as config from '@/config';
import { ActionContext, Module } from 'vuex';
import { get, set } from '@/utils/vuex';
import { ackeeAction } from '@/plugins/ackee';
import Cookies from 'js-cookie';
import i18n from '@/plugins/i18n';
import { IPage } from '@/api';
import { IRootState } from '..';
import { setupPageTracking } from '@/utils/tracking';

export interface IPageState {
  page: IPage | null;
  showCookieDialog: boolean;
}

const state: IPageState = {
  page: null,
  showCookieDialog: false,
};

const getters = {
  page: get('page'),
  showCookieDialog: get('showCookieDialog'),
};

const mutations = {
  setPage: set('page'),
  setShowCookieDialog: set('showCookieDialog'),
};

const COOKIE_CONSENT_NAME = 'cookieConsent';

const actions = {
  async resolvePage({ commit }: ActionContext<IPageState, IRootState>): Promise<IPage> {
    // allows testing when running locally, and requesting
    // against the api.pagefate.dev, when accesing
    // http://nodepit.pagefate.test:8080/, the following
    // code will explicitly set the hostname to
    // `nodepit.pagefate.dev`
    const { hostnameSearch, hostnameReplace } = await config.load();
    let hostname: string | undefined = undefined;
    if (typeof hostnameSearch === 'string' && typeof hostnameReplace === 'string') {
      hostname = window.location.hostname.replace(hostnameSearch, hostnameReplace);
    }
    const page = await api.resolvePage({ hostname });
    if (page.googleAnalytics) {
      if (Cookies.get(COOKIE_CONSENT_NAME) === '1') {
        setupPageTracking(page.googleAnalytics);
      } else if (Cookies.get(COOKIE_CONSENT_NAME) !== '0') {
        commit('setShowCookieDialog', true);
      }
    }
    i18n.locale = page.language;
    commit('setPage', page);
    return page;
  },
  closeCookieDialog({ commit, state }: ActionContext<IPageState, IRootState>, outcome: 'agree' | 'reject'): void {
    commit('setShowCookieDialog', false);
    if (outcome === 'agree') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setupPageTracking(state.page!.googleAnalytics!);
      ackeeAction('click', { key: 'cookie-agree', value: 1 });
      Cookies.set(COOKIE_CONSENT_NAME, '1');
    } else {
      ackeeAction('click', { key: 'cookie-reject', value: 1 });
      Cookies.set(COOKIE_CONSENT_NAME, '0');
    }
  },
};

const page: Module<IPageState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default page;
