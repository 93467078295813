import component, { IComponentState } from './modules/component';
import event, { IEventState } from './modules/event';
import loading, { ILoadingState } from './modules/loading';
import page, { IPageState } from './modules/page';
import toast, { IToastState } from './modules/toast';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export interface IRootState {
  component: IComponentState;
  event: IEventState;
  loading: ILoadingState;
  page: IPageState;
  toast: IToastState;
}

export default new Vuex.Store<IRootState>({
  modules: {
    component,
    event,
    loading,
    page,
    toast,
  },
});
