









import { Component, Prop, Vue } from 'vue-property-decorator';
import { ImpactType } from '@/api';

@Component
export default class PageImpact extends Vue {
  @Prop() private readonly impact: ImpactType | undefined;

  get foreground(): string {
    return 'white';
  }

  get background(): string {
    switch (this.impact) {
      case 'operational':
        return '#48b975';
      case 'degraded':
      case 'partial':
      case 'outage':
        return '#f38020';
      case 'maintenance':
      default:
        return '#2c7cb0';
    }
  }

  get text(): string {
    switch (this.impact) {
      case 'operational':
      case 'maintenance':
      case 'degraded':
      case 'partial':
      case 'outage':
        return `pageImpact.${this.impact}`;
      default:
        return 'pageImpact.unknown';
    }
  }

  get icon(): string {
    switch (this.impact) {
      case 'operational':
        return 'mdi-check-circle-outline';
      case 'maintenance':
        return 'mdi mdi-alert-circle-outline';
      case 'degraded':
      case 'partial':
      case 'outage':
        return 'mdi mdi-alert-circle-outline';
      default:
        return 'mdi-help-circle-outline';
    }
  }
}
