import * as config from '@/config';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';

(async () => {
  const { sentryEnvironment } = await config.load();
  if (typeof sentryEnvironment !== 'string') {
    throw new Error('Sentry configuration is missing');
  }
  Sentry.init({
    Vue,
    dsn: 'https://2f5fe9fd09314fc0a75dc7172e8cbc40@o763015.ingest.sentry.io/5793735',
    environment: sentryEnvironment,
    integrations: [new Integrations.BrowserTracing()],
    attachProps: true,
    logErrors: true,
    tracesSampleRate: 1.0,
  });
})().catch(() => {
  // do nothing
});
