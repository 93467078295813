import * as api from '@/api';
import { ActionContext, Module } from 'vuex';
import { get, set } from '@/utils/vuex';
import { IEvent, ObjectId } from '@/api';
import { IRootState } from '..';

export interface IEventState {
  event: IEvent | null;
  events: IEvent[];
  unresolvedEvents: IEvent[];
}

export interface IListEventsParams {
  pageId: ObjectId;
  resolved?: boolean;
  createdBefore?: string;
  createdAfter?: string;
}

const state: IEventState = {
  event: null,
  events: [],
  unresolvedEvents: [],
};

const getters = {
  event: get('event'),
  events: get('events'),
  unresolvedEvents: get('unresolvedEvents'),
};

const mutations = {
  setEvent: set('event'),
  setEvents: set('events'),
  setUnresolvedEvents: set('unresolvedEvents'),
};

const actions = {
  async listEvents({ commit }: ActionContext<IEventState, IRootState>, params: IListEventsParams): Promise<IEvent[]> {
    const { pageId, ...rest } = params;
    const events = await api.listEvents(pageId, rest);
    commit('setEvents', events);
    return events;
  },
  async listUnresolvedEvents({ commit }: ActionContext<IEventState, IRootState>, pageId: ObjectId): Promise<IEvent[]> {
    const events = await api.listEvents(pageId, { resolved: false });
    commit('setUnresolvedEvents', events);
    return events;
  },
  async getEvent({ commit }: ActionContext<IEventState, IRootState>, eventId: string): Promise<IEvent> {
    const event = await api.getEvent(eventId);
    commit('setEvent', event);
    return event;
  },
};

const event: Module<IEventState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default event;
