












































import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IEvent, IPage } from '@/api';
import { ackeeAction } from '@/plugins/ackee';
import { IListEventsParams } from '@/store/modules/event';
import { MetaInfo } from 'vue-meta';
import PageEvent from '@/components/PageEvent.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageHeader from '@/components/PageHeader.vue';

@Component<PageHistory>({
  components: { PageHeader, PageFooter, PageEvent },
  metaInfo(): MetaInfo {
    return {
      title: this.page ? `${this.page.name}: History` : 'History',
    };
  },
})
export default class PageHistory extends Vue {
  protected year: number = new Date().getFullYear();

  @Getter
  protected page!: IPage;

  @Getter
  protected events!: IEvent[];

  @Action
  protected listEvents!: (params: IListEventsParams) => Promise<void>;

  @Watch('year', { immediate: true })
  protected async onYearChanged(year: number): Promise<void> {
    return this.listEvents({
      pageId: this.page._id,
      resolved: true,
      createdAfter: new Date(year, 0, 1).toISOString(),
      createdBefore: new Date(year + 1, 0, 1).toISOString(),
    });
  }

  get isCurrentYear(): boolean {
    return this.year === new Date().getFullYear();
  }

  protected incrementYear(): void {
    ackeeAction('click', { key: 'year', value: 1 });
    this.year++;
  }

  protected decrementYear(): void {
    ackeeAction('click', { key: 'year', value: 1 });
    this.year--;
  }
}
