

















import * as config from '@/config';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PageFooter extends Vue {
  protected url = 'https://pagefate.com';

  async created(): Promise<void> {
    const data = await config.load();
    this.url = data.websiteUrl;
  }
}
