







import { Component, Vue } from 'vue-property-decorator';
import CookieDialog from './components/CookieDialog.vue';

@Component<App>({
  components: { CookieDialog },
  metaInfo: {
    titleTemplate: '%s | PageFate',
  },
})
export default class App extends Vue {
  // empty for now
}
