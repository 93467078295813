

































import * as api from '../api';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { ackeeAction } from '@/plugins/ackee';
import { emailRules } from '@/utils/validation';
import { headerTextColor } from '@/utils/display';
import i18n from '@/plugins/i18n';
import { IPage } from '@/api';
import { IToast } from '@/store/modules/toast';

@Component<SubscriberButton>({
  data: () => ({ emailRules: emailRules }),
  methods: { headerTextColor },
})
export default class SubscriberButton extends Vue {
  protected menu = false;
  protected valid = false;
  protected email = '';

  @Getter
  protected isLoading!: boolean;

  @Getter
  protected page!: IPage;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected async onSubscribe(): Promise<void> {
    try {
      ackeeAction('click', { key: 'subscribe', value: 1 });
      await api.subscribe({ email: this.email, page: this.page._id });
      this.setToast({ message: i18n.t('subscriberButton.successMessage') as string });
    } catch (e) {
      this.setToast({
        message: i18n.t('subscriberButton.errorMessage') as string,
        type: 'error',
      });
    } finally {
      this.email = '';
      this.valid = false;
      this.menu = false;
    }
  }
}
