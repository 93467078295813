import { ackeeHook } from '@/plugins/ackee';
import { confirmSubscriber } from '@/router/guards/confirmSubscriber.guard';
import Error from '@/views/Error.vue';
import isValidPageGuard from '@/router/guards/isValidPage.guard';
import NotFound from '@/views/NotFound.vue';
import PageHistory from '@/views/PageHistory.vue';
import PageStatus from '@/views/PageStatus.vue';
import Router from 'vue-router';
import { unsubscribeSubscriber } from '@/router/guards/unsubscribeSubscriber.guard';
import Vue from 'vue';
import Wrapper from '@/views/Wrapper.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/confirm-subscriber',
      name: 'confirm-subscriber',
      component: Wrapper,
      beforeEnter: confirmSubscriber,
    },
    {
      path: '/unsubscribe-subscriber',
      name: 'unsubscribe-subscriber',
      component: Wrapper,
      beforeEnter: unsubscribeSubscriber,
    },
    {
      path: '/',
      component: Wrapper,
      redirect: { name: 'status' },
      beforeEnter: isValidPageGuard,
      children: [
        {
          path: '',
          name: 'status',
          component: PageStatus,
        },
        {
          path: '/history',
          name: 'history',
          component: PageHistory,
        },
      ],
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '',
      name: 'error',
      component: Error,
    },
  ],
});

router.afterEach(ackeeHook);

export default router;
